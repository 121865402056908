@use "variable" as var;
/*Mapbox*/
/*レーダー画面*/
.mapboxgl-ctrl-group {
  opacity: 0;
  pointer-events: none;
}

.mapRadarMode {
  .mapboxgl-user-location-dot,
  .mapboxgl-user-location-dot:before {
    background-color: gold;
  }
  .mapboxgl-user-location-show-heading .mapboxgl-user-location-heading:after,
  .mapboxgl-user-location-show-heading .mapboxgl-user-location-heading:before {
    border-bottom: 7.5px solid gold;
  }
}

.mapMainMode {
  .mapboxgl-user-location-accuracy-circle {
    opacity: 0;
  }
  .mapboxgl-user-location-dot {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .mapboxgl-user-location-dot:before {
    background-color: rgba(255, 215, 0, 0.8);
    width: 20px;
    height: 20px;
  }
  .mapboxgl-user-location-dot:after {
    opacity: 0;
  }
  .mapboxgl-user-location-show-heading .mapboxgl-user-location-heading {
    opacity: 0;
  }
}

// .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
//   margin: 0 10px 5px 0 !important;
// }

.next-error-h1 {
  color: black;
  & + div {
    color: black;
  }
}

.mapboxgl-popup-content {
  border: 3px solid rgb(230, 180, 34) !important;
  border-radius: 20px !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgb(230, 180, 34) !important;
}

.mapboxgl-popup-close-button {
  display: none;
}
